import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Chip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import tellUsImage from "../../assets/images/tell-us-about-you-image.png";
import setItinerary from "../../assets/images/set-itinerary-image.png";
import collaborateImage from "../../assets/images/collaborate-image.png";
import enjoyItinerary from "../../assets/images/enjoy-your-itinerary-image.png";
import alwaysLearningImage from "../../assets/images/always-learning-image.png";

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  textAlign: "left",
  flex: 1,
  width: "100%",
}));

const AbsoluteImage = styled("img")(({ theme }) => ({
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    marginTop: theme.spacing(2),
    transform: "translateX(0)",
    maxWidth: "100%",
    margin: "0 auto",
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  width: "fit-content",
  fontWeight: "bold",
  fontSize: 50,
  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
  },
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontSize: 25,
  [theme.breakpoints.down("sm")]: {
    fontSize: 15,
  },
}));

const SectionComponent = ({ title, content, ...props }) => (
  <Section sx={{ ...props }}>
    <TitleTypography fontWeight='bold' gutterBottom>
      {title}
    </TitleTypography>
    {content && <ContentTypography>{content}</ContentTypography>}
  </Section>
);

const TravelPlatformSection = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(4, 0),
  marginBottom: "5px",
}));

const CardContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  // [theme.breakpoints.down("sm")]: {
  //   margin: 15,
  // },
}));

const TravelCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "left",
  color: "#E0FFF8",
  backgroundColor: "#02434A",
  borderRadius: "10px",
  position: "relative",
  // width: "85%",
  "& p": {
    lineHeight: 2,
  },
}));

const ComingSoonChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: "#93FFE6",
  color: "#02434A",
  borderRadius: "5px",
  fontSize: "10px",
  fontWeight: "bold",
}));

const HoverGrid = styled(Grid)(({ theme, isSmallScreen }) => ({
  display: "flex",
  marginBottom: theme.spacing(10),
  marginLeft: theme.spacing(5),
  flexDirection: isSmallScreen ? "column" : "row",
  opacity: 1,
  transition: isSmallScreen
    ? "none"
    : "opacity 0.1s ease-out, transform 0.3s ease-out",
  transform: "translateY(0)",
  "&:hover": {
    opacity: isSmallScreen ? 1 : 1,
    transform: isSmallScreen ? "none" : "translateY(-15px)",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "left",
    justifyContent: "center",
    alignContent: "center",
    marginBottom: 0,
    marginLeft: 0,
  },
}));

const SectionsContainer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const cardsRef = useRef([]);

  return (
    <>
      <Typography fontSize={50} fontWeight='bold' mb={isSmallScreen ? 0 : 20} gutterBottom>
        Let’s Look at Your Journey on Itini
      </Typography>
      <Grid container direction='column' spacing={3} mt={10}>
        <HoverGrid
          item
          xs={12}
          md={4}
          ref={(el) => (cardsRef.current[0] = el)}
          isSmallScreen={isSmallScreen}
        >
          <SectionComponent
            title='Tell us about you'
            content='From the moment you start on our app, Itini focuses on you. Set your preferences and set them once. We are here to understand your overall habits to give you personalized results.'
          />
          <Box flex={1} position='relative'>
            <AbsoluteImage
              src={tellUsImage}
              alt='tell us about you'
              sx={{
                left: "45%",
                bottom: "-100%",
                transform: "translateX(-50%) scale(0.6)",
                [theme.breakpoints.down("md")]: {
                  transform: "translateX(-50%) scale(0.4)",
                  bottom: "-50%",
                },
                [theme.breakpoints.down("sm")]: {
                  left: "0%",
                  transform: "translateX(0) scale(1.2)",
                },
              }}
            />
          </Box>
        </HoverGrid>

        <HoverGrid
          item
          xs={12}
          md={4}
          ref={(el) => (cardsRef.current[1] = el)}
          isSmallScreen={isSmallScreen}
        >
          <Box flex={1} position='relative'>
            <AbsoluteImage
              src={setItinerary}
              alt='Set your itinerary details'
              sx={{
                left: "35%",
                top: "-135%",
                transform: "translateX(-50%) scale(0.6)",
                [theme.breakpoints.down("md")]: {
                  transform: "translateX(-50%) scale(0.4)",
                  top: "-65%",
                  left: "35%",
                },
                [theme.breakpoints.down("sm")]: {
                  left: "0%",
                  transform: "translateX(0) scale(1)",
                },
              }}
            />
          </Box>
          <SectionComponent
            title='Set your itinerary details'
            content='Set your search details of your trip in our simple search layout. Plan for just one day for one or for multiple days.'
          />
        </HoverGrid>

        <HoverGrid
          item
          xs={12}
          md={4}
          ref={(el) => (cardsRef.current[2] = el)}
          isSmallScreen={isSmallScreen}
        >
          <SectionComponent
            title='Collaborate'
            content='Our collaboration feature allows you to add your guests to the itinerary, matching their preferences and yours to produce an itinerary for all to enjoy. We want to remind people how it it felt to have fun by interact with others.'
          />
          <Box flex={1} position='relative'>
            <AbsoluteImage
              src={collaborateImage}
              alt='collaborate'
              sx={{
                left: "40%",
                top: "-110%",
                transform: "translateX(-50%) scale(0.4)",
                [theme.breakpoints.down("md")]: {
                  transform: "translateX(-50%) scale(0.3)",
                  top: "-55%",
                  left: "35%",
                },
                [theme.breakpoints.down("sm")]: {
                  left: "0%",
                  transform: "translateX(0) scale(1)",
                },
              }}
            />
          </Box>
        </HoverGrid>

        <HoverGrid
          item
          xs={12}
          md={4}
          ref={(el) => (cardsRef.current[3] = el)}
          isSmallScreen={isSmallScreen}
        >
          <Box flex={1} position='relative'>
            <AbsoluteImage
              src={enjoyItinerary}
              alt='itinerary'
              sx={{
                left: "40%",
                bottom: "-80%",
                transform: "translateX(-50%) scale(0.5)",
                [theme.breakpoints.down("md")]: {
                  transform: "translateX(-50%) scale(0.3)",
                  bottom: "-30%",
                },
                [theme.breakpoints.down("sm")]: {
                  left: "0%",
                  transform: "translateX(0) scale(1)",
                },
              }}
            />
          </Box>
          <SectionComponent
            title='Enjoy your itinerary'
            content='We listened to every single one of you during our previous models. You have all loved the simplicity of your user experience. Now that everything has been planned all you have to do is show up! Find important details of the venue right inside your itinerary.'
          />
        </HoverGrid>

        <HoverGrid
          item
          xs={12}
          md={4}
          ref={(el) => (cardsRef.current[4] = el)}
          isSmallScreen={isSmallScreen}
        >
          <SectionComponent
            title='Always learning'
            content='Our AI evolves with you. With each interaction such as your shuffles or your favored places, our technology gets smarter and better at suggesting recommendations that you will enjoy and that properly aligns with your desires.'
          />
          <Box flex={1} position='relative'>
            <AbsoluteImage
              src={alwaysLearningImage}
              alt='always learning'
              sx={{
                left: "30%",
                bottom: "-110%",
                transform: "translateX(-50%) scale(0.4)",
                [theme.breakpoints.down("md")]: {
                  transform: "translateX(-50%) scale(0.3)",
                  bottom: "-60%",
                  left: "40%",
                },
                [theme.breakpoints.down("sm")]: {
                  left: "0%",
                  transform: "translateX(0) scale(1)",
                },
              }}
            />
          </Box>
        </HoverGrid>
      </Grid>
      <TravelPlatformSection>
        <Typography variant='h4' fontSize='3rem' gutterBottom>
          Ultimate Travel Platform
        </Typography>
        <CardContainer container spacing={2} justifyContent='center'>
          <Grid item xs={12} md={2}>
            <TravelCard>
              <Typography variant='h6'>Explore</Typography>
              <Typography variant='body2'>
                Self-plan and explore your new and local community gems.
              </Typography>
            </TravelCard>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: isSmallScreen ? "0" : "5%" }}>
            <TravelCard>
              <Typography variant='h6'>Collaborate</Typography>
              <Typography variant='body2'>
                Quickly create experiences with others that align with your
                desires and theirs.
              </Typography>
            </TravelCard>
          </Grid>
          <Grid item xs={12} md={2}>
            <TravelCard>
              <ComingSoonChip label='Coming soon' />
              <Typography variant='h6'>Deals</Typography>
              <Typography variant='body2'>
                Apply available local deals, discounts and giveaways to keep you
                in budget.
              </Typography>
            </TravelCard>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: isSmallScreen ? "0" : "5%" }}>
            <TravelCard>
              <ComingSoonChip label='Coming soon' />
              <Typography variant='h6'>XR</Typography>
              <Typography variant='body2'>
                Our immersive technology reveals details and increases
                confidence in your coming experience.
              </Typography>
            </TravelCard>
          </Grid>
          <Grid item xs={12} md={2}>
            <TravelCard>
              <ComingSoonChip label='Coming soon' />
              <Typography variant='h6'>Live</Typography>
              <Typography variant='body2'>
                Get location live updates on your itinerary for traffic and
                weather.
              </Typography>
            </TravelCard>
          </Grid>
        </CardContainer>
      </TravelPlatformSection>
    </>
  );
};

export default SectionsContainer;
