export const calculateTimeLeft = () => {
  const now = new Date();
  const targetDate = new Date('2024-10-27');
  const difference = targetDate - now;
  let timeLeft = {};

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    timeLeft = { days, hours, minutes, seconds };
  }

  return timeLeft;
};