import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import PartnershipPage from "./pages/PartnershipPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import LandingPage from "./pages/LandingPage";
import "@fontsource/ubuntu";
import ItiniHunt from "./pages/ItiniHunt";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
  typography: {
    fontFamily: "Ubuntu, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#02434A",
    },
    secondary: {
      main: "#E0FFF8",
    },
    text: {
      primary: "#02434A",
    },
    background: {
      default: "white",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/partnership' element={<PartnershipPage />} />
          <Route path='/about-us' element={<AboutUsPage />} />
          <Route path='/contact-us' element={<ContactPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/itini-hunt' element={<ItiniHunt />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
