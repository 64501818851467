import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";
import FooterComponent from "../components/StaticPages/FooterComponent";
import image1 from "../assets/images/80-percent.png";
import image2 from "../assets/images/groups.png";
import image3 from "../assets/images/itini-hunt-phone.png";
import image4 from "../assets/images/2m-customers.png";
import image5 from "../assets/images/30-restaurants.png";
import image6 from "../assets/images/72-percent.png";
import image7 from "../assets/images/92-percent.png";
import ItiniHuntAppBar from "../components/StaticPages/ItiniHuntAppBar";

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  textAlign: "left",
  marginTop: theme.spacing(5),
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: "#C4FFF1",
  fontWeight: "bold",
  marginBottom: theme.spacing(5),
  fontSize: 30,
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  width: "80%",
  marginBottom: 15,
  fontSize: 25,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "60%",
  },
}));

const ItiniHunt = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <ItiniHuntAppBar />
      <Root sx={{ bgcolor: "#02434A" }}>
        <Slider {...settings}>
          <Section bgcolor='#02434A' minHeight='1005' id='pain-point'>
            <Typography fontSize={50} fontWeight='bold' color='#C4FFF1'>
              ITINI
            </Typography>
            <Typography
              ml={5}
              fontSize={50}
              fontWeight='bold'
              color='#C4FFF1'
              mb={5}
            >
              HUNTS
            </Typography>
            <TitleTypography>
              PAIN POINT (STATISTICAL PROBLEM IN THE INDUSTRY)
            </TitleTypography>
            <ContentTypography mb={5}>
              - 80% of Customers Are Likely to Choose Restaurants with Unique
              Experiences
            </ContentTypography>
            <Box
              display='flex'
              justifyContent='center'
              gap={25}
              alignItems='center'
              padding={2}
              sx={{
                gap: { xs: 2, sm: 5 },
              }}
            >
              <Image src={image1} alt='80%' />
              <Image src={image2} alt='Groups' />
            </Box>
            <Typography
              fontWeight='bold'
              fontSize={20}
              mt={5}
              mb={5}
              color='#C4FFF1'
            >
              Today's diners expect more than just good food—they want an
              experience. With long wait times and stiff competition, how do you
              stand out?
            </Typography>
          </Section>

          <Section bgcolor='#02434A' minHeight='100%'>
            <Typography fontSize={50} fontWeight='bold' color='#C4FFF1'>
              ITINI
            </Typography>
            <Typography
              ml={5}
              fontSize={50}
              fontWeight='bold'
              color='#C4FFF1'
              mb={5}
            >
              HUNTS
            </Typography>
            <TitleTypography>
              PAIN POINT (STATISTICAL PROBLEM IN THE INDUSTRY)
            </TitleTypography>
            <ContentTypography mb={5}>
              - Research shows that 70% of customers won’t return if they
              experience long wait times or poor service
            </ContentTypography>
            <Box
              display='flex'
              justifyContent='center'
              sx={{
                gap: { xs: 2, sm: 5 },
              }}
              alignItems='center'
              padding={2}
            >
              <Image src={image6} alt='72%' />
              <Image src={image2} alt='Groups' />
            </Box>
            <Typography
              fontWeight='bold'
              fontSize={20}
              mt={5}
              mb={5}
              color='#C4FFF1'
            >
              Today's diners expect more than just good food—they want an
              experience. With long wait times and stiff competition, how do you
              stand out?
            </Typography>
          </Section>

          <Section bgcolor='#02434A'>
            <Typography fontSize={50} fontWeight='bold' color='#C4FFF1'>
              ITINI
            </Typography>
            <Typography
              ml={5}
              fontSize={50}
              fontWeight='bold'
              color='#C4FFF1'
              mb={5}
            >
              HUNTS
            </Typography>
            <TitleTypography>
              PAIN POINT (STATISTICAL PROBLEM IN THE INDUSTRY)
            </TitleTypography>
            <ContentTypography mb={5}>
              - Nearly 92% of customers read online reviews before choosing a
              restaurant, and 33% won’t go to a place with fewer than four stars
            </ContentTypography>
            <Box
              display='flex'
              justifyContent='center'
              sx={{
                gap: { xs: 2, sm: 5 },
              }}
              alignItems='center'
              padding={2}
            >
              <Image src={image7} alt='92%' />
              <Image src={image2} alt='Groups' />
            </Box>
            <Typography
              fontWeight='bold'
              fontSize={20}
              mb={5}
              mt={5}
              color='#C4FFF1'
            >
              Today's diners expect more than just good food—they want an
              experience. With long wait times and stiff competition, how do you
              stand out?
            </Typography>
          </Section>
        </Slider>

        <Section bgcolor='#01545C' id='solution'>
          <Box
            display='flex'
            flexDirection={{ xs: "column", md: "row" }}
            alignItems='center'
          >
            <Box>
              <TitleTypography>Our Solution</TitleTypography>
              <ContentTypography mb={5}>
                Elevate Customer Engagement with Digital Scavenger Hunts
              </ContentTypography>
              <Typography
                fontWeight='bold'
                fontSize={20}
                mb={5}
                color='#C4FFF1'
              >
                We bring your restaurant's story to life through customized
                scavenger hunts, turning idle wait times into interactive
                moments that enhance brand loyalty.
              </Typography>
              <Typography
                fontWeight='bold'
                fontSize={20}
                mb={5}
                color='#C4FFF1'
              >
                Use this as a way to upsell your menu, your aesthetics, or your
                company culture. Let us help you directly deliver this message
                to your customer.
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              padding={2}
            >
              <Image src={image3} alt='phone image' />
            </Box>
          </Box>
        </Section>

        <Section bgcolor='#02434A' id='benefits'>
          <TitleTypography>KEY BENEFITS</TitleTypography>
          <Box mb={10}>
            <ContentTypography>Innovative Organic Marketing:</ContentTypography>
            <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
              Create organic traffic encourages customers to engage and share
              feedback in a more meaningful, detailed way, providing you with
              richer data to enhance your service.
            </Typography>
          </Box>
          <Box mb={10}>
            <ContentTypography>Engage Your Customers:</ContentTypography>
            <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
              Storytelling and gamification that highlight your entire
              restaurant experience.
            </Typography>
          </Box>
          <Box mb={10}>
            <ContentTypography>Real-Time Feedback:</ContentTypography>
            <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
              Gather valuable data and reviews to improve customer satisfaction.
            </Typography>
          </Box>
          <Box mb={10}>
            <ContentTypography>Fill Wait Times:</ContentTypography>
            <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
              Offer engaging activities while customers wait, enhancing their
              overall experience.
            </Typography>
          </Box>
          <Box mb={10}>
            <ContentTypography>Build Brand Community:</ContentTypography>
            <Typography fontWeight='bold' fontSize={20} color='#C4FFF1'>
              Increase retention and attract new customers with our co-branding
              algorithm and local restaurant community.
            </Typography>
          </Box>
        </Section>

        <Section bgcolor='#01545C' id='stats'>
          <Box flex={1}>
            <TitleTypography>SUCCESS STATS</TitleTypography>
            <ContentTypography>PROVEN SUCCESS</ContentTypography>
            <Typography
              fontWeight='bold'
              mb={5}
              mt={5}
              fontSize={20}
              color='#FFFFFF'
            >
              Our platform connects you with over 2 million customers and is
              already trusted by 30 restaurants. Together, we’re reshaping
              dining experiences.
            </Typography>
            <Typography fontWeight='bold' mb={5} fontSize={20} color='#FFFFFF'>
              Research shows that 70% of customers won’t return if they
              experience long wait times or poor service.
            </Typography>
            <Typography fontWeight='bold' mb={5} fontSize={20} color='#FFFFFF'>
              Nearly 92% of customers read online reviews before choosing a
              restaurant, and 33% won’t go to a place with fewer than four
              stars.
            </Typography>
          </Box>
          <Box
            flex={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              gap: { xs: 1, sm: 5, md: 25 },
            }}
          >
            <Image src={image4} alt='2m+ customers' />
            <Image src={image5} alt='30+ restaurants' />
          </Box>
        </Section>

        <Section
          display='flex'
          flexDirection='column'
          alignItems='center'
          bgcolor='#02434A'
          textAlign='center'
        >
          <Typography fontWeight='bold' color='#C4FFF1' fontSize={30} mb={5}>
            READY TO ELEVATE YOUR BRAND?
          </Typography>
          <ContentTypography>
            Contact us today for a demo and discover how gamified experiences
            can set your restaurant apart.
          </ContentTypography>
          <Button
            variant='contained'
            sx={{
              bgcolor: "#C4FFF1",
              color: "#000000",
              fontWeight: "bold",
              borderRadius: 5,
              "&:hover": {
                backgroundColor: "#93FFE6",
              },
            }}
            href='https://tkwdef6eult.typeform.com/to/eoJBvAUl'
            target='_blank'
            rel='noopener noreferrer'
          >
            Get Started
          </Button>
        </Section>
      </Root>
      <FooterComponent backgroundColor='#01545C' color='#FFFFFF' />
    </>
  );
};

export default ItiniHunt;
