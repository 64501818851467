import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { calculateTimeLeft } from "../../utils/calculateTimeLeft";

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Countdown = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 10),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0, 0, 6),
  },
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0, 0, 5),
  },
}));

const CountdownNumber = styled(Typography)(({ theme }) => ({
  fontSize: "15rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  animation: `${pulseAnimation} 2s infinite`,
  maxWidth: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: "10rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
}));

const CountdownContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(8),
  "@media (max-width: 1350px)": {
    gap: theme.spacing(4),
  },
  "@media (max-width: 950px)": {
    gap: theme.spacing(1),
  },
}));

const CountdownItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  textAlign: "center",
}));

const CountdownLabel = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  color: "black",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const CountdownComponent = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatNumber = (number) => {
    return String(number).padStart(2, "0");
  };

  return (
    <Countdown>
      <CountdownContainer>
        <CountdownItem>
          <CountdownNumber>{formatNumber(timeLeft.days || 0)}</CountdownNumber>
          <CountdownLabel variant='subtitle1'>Days</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownNumber>{formatNumber(timeLeft.hours || 0)}</CountdownNumber>
          <CountdownLabel variant='subtitle1'>Hours</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownNumber>
            {formatNumber(timeLeft.minutes || 0)}
          </CountdownNumber>
          <CountdownLabel variant='subtitle1'>Mins</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownNumber>
            {formatNumber(timeLeft.seconds || 0)}
          </CountdownNumber>
          <CountdownLabel variant='subtitle1'>Secs</CountdownLabel>
        </CountdownItem>
      </CountdownContainer>

      <Typography variant='h6' mt={5} color='black' fontWeight='bold'>
        Until our next exciting update!
      </Typography>

      <Link href='https://itini.ai/'>
        <Button
          sx={(theme) => ({
            padding: "10px 25px",
            backgroundColor: "#E0FFF8",
            color: "#02434A",
            fontWeight: "bold",
            mt: 5,
            [theme.breakpoints.down("sm")]: {
              padding: "8px 20px",
            },
          })}
        >
          Experience Beta 2.0
        </Button>
      </Link>
    </Countdown>
  );
};

export default CountdownComponent;
