import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { styled } from "@mui/system";
import AppBarComponent from "../components/StaticPages/AppBarComponent";
import FooterComponent from "../components/StaticPages/FooterComponent";

const Container = styled(Box)(({ theme }) => ({
  padding: "5% 2%",
  marginTop: "12%",
  marginLeft: "5%",
  marginRight: "5%",
  textAlign: "left",
  [theme.breakpoints.down("md")]: {
    padding: "5% 2%",
    marginTop: "20%",
    marginLeft: "3%",
    marginRight: "3%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5% 1%",
    marginTop: "35%",
    marginLeft: "2%",
    marginRight: "2%",
  },
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 50,
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  paragraph: true,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    marginBottom: 25,
  },
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  width: "95%",
  padding: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const JoinButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#02434A",
  color: "#FFFFFF",
  fontWeight: "bold",
  marginTop: theme.spacing(2),
  "&:hover": {
    backgroundColor: "#023A3C",
  },
}));

const PartnerPage = () => {
  return (
    <>
      <AppBarComponent title='Partners' />
      <Container>
        <Box>
          <Section>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <TitleTypography
                  gutterBottom
                >
                  Partnership
                </TitleTypography>
                <BodyTypography>
                  At Itini, we are revolutionizing travel with our AI-powered
                  experience planning application. Our innovative platform
                  leverages cutting-edge technology to provide personalized
                  itineraries for seamless travel experiences.
                </BodyTypography>
                <BodyTypography>
                  We believe in the power of collaboration to enhance the user's
                  experience and are excited to partner with industry leaders to
                  expand our offerings and deliver exceptional value to
                  travelers worldwide.
                </BodyTypography>
              </Grid>
            </Grid>
          </Section>
          <Section>
            <TitleTypography textAlign='center'>
              How do we collaborate?
            </TitleTypography>
            <Grid container spacing={3} justifyContent='center' mt={2}>
              <Grid item xs={12} md={4}>
                <StyledPaper>
                  <TitleTypography>
                    Co-Brand
                  </TitleTypography>
                  <BodyTypography>
                    Accelerate your company's growth and technology by
                    revolutionizing your user's experience.
                  </BodyTypography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper>
                  <TitleTypography>
                    Advertise
                  </TitleTypography>
                  <BodyTypography>
                    Gain visibility in our explore section through our
                    advertising program.
                  </BodyTypography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper>
                  <TitleTypography>
                    Affiliate
                  </TitleTypography>
                  <BodyTypography>
                    Monetize your platform and influence passively through
                    several of our unique features.
                  </BodyTypography>
                </StyledPaper>
              </Grid>
            </Grid>
            <Box textAlign='center' mt={4}>
              <JoinButton
                variant='contained'
                href='https://tkwdef6eult.typeform.com/to/eoJBvAUl'
                target='_blank'
                rel='noopener noreferrer'
              >
                Join Our Partners
              </JoinButton>
            </Box>
          </Section>
        </Box>
      </Container>
      <FooterComponent />
    </>
  );
};

export default PartnerPage;
