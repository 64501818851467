import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled, keyframes } from "@mui/system";
import AppBarComponent from "../components/StaticPages/AppBarComponent";
import CountdownComponent from "../components/StaticPages/CountdownComponent";
import SectionsContainer from "../components/StaticPages/SectionComponent";
import FooterComponent from "../components/StaticPages/FooterComponent";
import partnerImage from "../assets/images/partner-image.png";

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  textAlign: "center",
  marginTop: theme.spacing(20),
  marginLeft: theme.spacing(10),
  marginRight: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(15),
  },
  "@media (max-width: 1024px) and (max-height: 768px)": {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

const PartnerSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#E0FFF8",
  padding: theme.spacing(4, 0),
  textAlign: "center",
  position: "relative",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  marginLeft: theme.spacing(10),
  marginRight: theme.spacing(10),
  marginTop: theme.spacing(5),
  lineHeight: 5,
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  // "@media (max-width: 1024px) and (max-height: 768px)": {
  //   marginLeft: theme.spacing(3),
  //   marginRight: theme.spacing(3),
  //   marginTop: theme.spacing(3),
  // },
}));

const PartnerImage = styled("img")(({ theme }) => ({
  position: "absolute",
  left: "13%",
  top: "-50%",
  transform: "translateX(-50%) scale(0.7)",
  [theme.breakpoints.down("md")]: {
    left: "10%",
    transform: "translateX(-50%) scale(0.5)",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
  "@media (max-width: 1024px) and (max-height: 768px)": {
    left: "10%",
    transform: "translateX(-50%) scale(0.55)",
  },
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${fadeIn} 2s ease-out`,
  fontWeight: "bold",
  marginTop: theme.spacing(20),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(10),
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(5),
    fontSize: "1.2rem",
  },
  "@media (max-width: 1024px) and (max-height: 768px)": {
    marginTop: theme.spacing(15),
    fontSize: "1.4rem",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(40),
  marginRight: theme.spacing(40),
  fontSize: 20,
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(20),
    marginRight: theme.spacing(20),
    fontSize: 18,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 15,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  // "@media (max-width: 1024px) and (max-height: 768px)": {
  //   marginLeft: theme.spacing(15),
  //   marginRight: theme.spacing(15),
  //   fontSize: 18,
  // },
}));

const ResponsiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#02434A",
  color: "#FFFFFF",
  fontWeight: "bold",
  marginTop: "20px",
  padding: "5px 50px",
  [theme.breakpoints.down("md")]: {
    padding: "5px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 30px",
  },
  "@media (max-width: 1024px) and (max-height: 768px)": {
    padding: "5px 35px",
  },
}));

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <AppBarComponent itiniHunt />
      <Root>
        <AnimatedTypography variant='h5'>
          Your AI Experience Planner
        </AnimatedTypography>
        <CountdownComponent />
        <SectionsContainer />
      </Root>
      <PartnerSection>
        <PartnerImage src={partnerImage} alt='Partner with Itini' />
        <Box>
          <Typography fontWeight='bold' fontSize={30} gutterBottom>
            Partner with Itini
          </Typography>
          <ResponsiveTypography>
            Whether you want to collaborate or you are a travel vlogger, there
            is a place for you here with Itini!
          </ResponsiveTypography>
          <ResponsiveButton
            variant='outlined'
            onClick={() => navigate("/partnership")}
          >
            Join Us!
          </ResponsiveButton>
        </Box>
      </PartnerSection>
      <FooterComponent />
    </>
  );
};

export default LandingPage;
